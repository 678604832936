@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --scrollbar-thumb-light: rgba(176, 179, 184, 0.3);
  --scrollbar-track-light: transparent;
  --scrollbar-thumb-hover-light: rgba(176, 179, 184, 0.8);
  --scrollbar-thumb-dark: rgba(120, 125, 135, 0.3);
  --scrollbar-track-dark: transparent;
  --scrollbar-thumb-hover-dark: rgba(120, 125, 135, 0.8);
}

/* Global text color styles */
body {
  @apply bg-background-gray !overflow-x-hidden text-text-black dark:text-white dark:bg-black;
}

/* Force text color in dark mode */
.dark {
  color: white !important;
}

.dark * {
  color: white !important;
}

/* Exclude specific elements that should keep their color */
.dark .chatview__btn-send,
.dark .signin__btn,
.dark .signin__tos {
  color: inherit !important;
}

/* Global scrollbar styles */
body *:not(.show-scrollbar)::-webkit-scrollbar {
  display: none;
}

body *:not(.show-scrollbar) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@layer components {
  /* Modern scrollbar with auto-hide behavior */
  .show-scrollbar {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-light) transparent;
  }

  .show-scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  .show-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-light);
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  .show-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-hover-light);
  }

  /* Dark mode scrollbar */
  .dark .show-scrollbar {
    scrollbar-color: var(--scrollbar-thumb-dark) transparent;
  }

  .dark .show-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-dark);
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.05);
  }

  .dark .show-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-hover-dark);
  }

  /* CHATVIEW */
  .chatview {
    @apply flex flex-col h-screen overflow-hidden relative bg-slate-100 dark:bg-light-grey;
  }

  /* .chatview__chatarea {
    @apply flex-grow w-full overflow-y-scroll flex flex-col dark:bg-[#1B1C1E];
  } */

  .form {
    @apply flex justify-between items-stretch sm:flex-row bottom-0 left-0 right-0
    text-black dark:text-white;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  .prose {
    @apply text-gray-900 dark:text-gray-100;
  }

  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4 {
    @apply text-gray-900 dark:text-white font-bold mb-4;
  }

  .prose ul,
  .prose ol {
    @apply my-4 list-disc list-inside;
  }

  .prose li {
    @apply mb-2;
  }

  .prose strong {
    @apply font-bold text-gray-900 dark:text-white;
  }

  .chatview__textarea-message {
    @apply bg-light-white ml-0 sm:ml-2 grow p-2 
     overflow-y-hidden resize-y rounded-xl focus:outline-none dark:bg-[#1B1C1E]
     pr-14; /* Add padding to leave space for the button inside */
  }
  .chatview__textarea-message:focus {
    outline: none; /* Remove outline */
    box-shadow: none; /* Remove any box shadow */
  }

  .chatview__btn-send {
    @apply absolute right-2 top-1/2 transform -translate-y-1/2 rounded-lg cursor-pointer 
    bg-[#1414148A] dark:border-slate-500 text-white flex items-center justify-center 
    hover:bg-slate-200 hover:dark:bg-dark-grey transition ease-in-out duration-300 dark:border-white dark:bg-grid-gray;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }

  /* Signin Page */
  .signin {
    @apply flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-dark-grey;
  }

  .signin__container {
    @apply border-4 rounded-2xl h-72 w-72 p-5 flex flex-col justify-center items-center;
  }

  .signin__btn {
    @apply h-12 px-4 rounded-xl bg-slate-100 hover:bg-slate-300 border
     border-light-grey focus:ring-offset-indigo-200 text-black transition 
    ease-in-out duration-300 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .signin__tos {
    @apply text-white text-center font-bold p-2;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

.chat-menu {
  width: 1075px; /* Default margin for larger screens */
  margin: 0 auto;
}
@media (max-width: 1535px) {
  /* For screens smaller than 1024px */
  .chat-menu {
    width: 975px; /* Margin for large screens */
  }
}
@media (max-width: 1279px) {
  /* For screens smaller than 1024px */
  .chat-menu {
    width: 675px; /* Margin for large screens */
  }
}

@media (max-width: 1023px) {
  /* For screens smaller than 768px */
  .chat-menu {
    width: 535px; /* Margin for medium screens */
  }
}

@media (max-width: 767px) {
  /* For screens smaller than 480px */
  .chat-menu {
    width: 440px; /* No margin for small screens */
  }
}

@media (max-width: 639px) {
  /* For screens smaller than 480px */
  .chat-menu {
    width: 225px; /* No margin for small screens */
  }
}

/* Quill Editor Styles */
.quill {
  background: white;
  border-radius: 0.5rem;
}

.ql-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #f8fafc;
  border-color: #e2e8f0;
}

.ql-container {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-color: #e2e8f0;
  min-height: 200px;
}

.ql-editor {
  min-height: 200px;
  font-size: 16px;
}

/* Dark mode styles */
.dark .quill {
  background: #1f2937;
}

.dark .ql-toolbar {
  background: #374151;
  border-color: #4b5563;
}

.dark .ql-container {
  border-color: #4b5563;
}

.dark .ql-editor {
  color: white;
}

.dark .ql-snow .ql-stroke {
  stroke: white;
}

.dark .ql-snow .ql-fill {
  fill: white;
}

.dark .ql-picker-label {
  color: white;
}
